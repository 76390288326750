@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin links($color,$hover,$decoration, $weight) {

  a {
    &:link, &:visited, &:active {
      text-decoration: none;
      color: $color;
      font-weight: $weight;
    }

    &:hover, &:focus {
      color: $hover;
      text-decoration: $decoration;
    }
  }
}

@mixin links($color,$hover,$decoration, $weight) {

  a {
    &:link, &:visited, &:active {
      text-decoration: none;
      color: $color;
      font-weight: $weight;
    }

    &:hover, &:focus {
      color: $hover;
      text-decoration: $decoration;
    }
  }
}

@mixin lineover($color) {
  &:hover,
  &:active {

    .overlay-title {
      color: $black;
    }

    &:after {
      transform: scaleX(1);
    }
  }

  &:after {
    transform-origin: 0 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color;
    transform: scaleX(0);
    width: 100%;
    height: 5px;
    transition: transform 0.25s ease-in-out;
  }
}

@mixin middle-line($padding) {
  position: relative;
  padding-left: $padding;

  &:before {
    content: "";
    display: block;
    width: 50px;
    height: 1px;
    background-color: $black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

