// Colour Pallette
$black: #000000;
$white: #FFFFFF;
$yellow: #f2de0d;
$form: #efefef;
$gray: #adadad;
$error: #ff1414;
$border: #f0f0f0;
$inactive: #d6d6d6;
$list_border: #cbcbcb;
$light_gray: #f6f6f6;