// Override default variables of susy

// Returns a map with the default variables, the ones we set ourselves plus any new overrides
// susy-settings((columns: susy-repeat(12, 104px), gutters: 32px));

/* scss generating background grids

.grid {
  background: susy-svg-grid() no-repeat scroll;
}*/

$container: 1600px;

// Margins
$sect-marg: 50px;
$reg-marg: 150px;
$mid-marg: 100px;
$page-spacing: 200px;

$susy: (
        columns: susy-repeat(12), // or 12, 104px
        gutters: 0.30769230769231, // or 32px
        spread: 'narrow',
        container-spread: 'narrow',
);

$susy-static: (
        columns: susy-repeat(12, 104px),
        gutters: 32px,
        spread: 'narrow',
        container-spread: 'narrow',
);

@function statcol($columns) {
  @return span($columns, $susy-static);
}

@mixin container($width) {
  max-width: $width;
  margin: 0 auto;
}

@include breakpoint-set('default feature', 'max-width');
