@import "susy";
@import 'plugins/svg-grid';
@import "breakpoint";

@import "variables/_sprites.scss";
@import "variables/_grid.scss";
@import "variables/_typography.scss";
@import "variables/_colors.scss";
@import "abstractions/_mixins.scss";

$special-desktop: 1680px;
$desktop: 1640px;
$smaller-desktop: 1500px;
$laptop: 1366px;
$laptop-smaller: 1300px;
$mini-laptop: 1260px;
$other-tablet: 1200px;
$near-tablet: 1180px;
$tablet: 1024px;
$sub-tablet: 1010px;
$super-mega-mobile: 900px;
$mega-mobile: 800px;
$work: 780px;
$work-mobile: 680px;
$bmobile: 620px;
$mobile: 600px;
$mobile-mid: 500px;
$other-mobile: 460px;
$small-mobile: 440px;
$target-mobile: 360px;
$smaller-mobile: 350px;
$mini-mobile: 320px;

$unipadding: 20px;

@include breakpoint($special-desktop) {
  .below-sect-wrapper {
    margin-left: $unipadding;
    margin-right: $unipadding;
  }
}

@include breakpoint($desktop) {

  .l-header,
  .l-footer,
  .l-below-content,
  .l-subfooter,
  .page-faq .l-content,
  .page-our-work .l-main,
  .node--artist--full .node__content,
  a.wide-banner-link,
  .node-type-project .l-main,
  .featured-sect .fsec-title,
  .node--project--featured .group-meta-wrapper .group-meta,
  .logos-fsec,
  .last-sect,
  .messages,
  .block--nodeblock {
    margin-left: $unipadding;
    margin-right: $unipadding;
  }

  .main-videos-wrapper .titled-mega {
    padding-left: 20px;
    padding-right: 20px;
  }

/*  .node--artist--full .node__content {
    .artist-text-wrapper {
      .field--name-field-image {
        margin-left: -$unipadding;
        margin-right: -$unipadding;
      }
    }
  }*/

  .l-main {
    .field-collection-item-field-page-section {
      .group-text-section {
        margin-left: $unipadding;
        margin-right: $unipadding;
      }
    }

    .parallax-full__title {
      padding-left: $unipadding;
      padding-right: $unipadding;
    }
  }
}

@include breakpoint($smaller-desktop) {

  .switcher-main {
    .attachment-before {
      min-width: statcol(2);

      .views-row {
        font-size: 0.95454rem;
      }
    }

    > .view-content {
      margin-left: span(2 wide, $susy-static);
    }
  }
}

$reg-marg: 120px;

// base font size is 18 now

$normal: 0.77777rem; // 16px -> 14px
$slight: 0.88888rem; // 18px -> 16px
$base: 1rem; // 18px base
/*  $bigger: 1.18181rem; // 26px
  $lar: 1.36363rem; // 30px;
  $large: 1.45454rem; // 32px
  $larger: 1.63636rem; // 36px
  $huge: 2.54545rem; // 56px
  $giga: 4.54545rem; // 100px;*/

@include breakpoint($laptop) {

  html {
    font-size: 18px;
    line-height: 1.5;
  }

  body {
    padding-top: 69px;
  }


  .block--system-main-menu a {
    line-height: 1.6;
  }

  .field--name-field-page-section {
    .field-group-html-element {
      &.group-text-section {
        margin-bottom: $reg-marg;
      }

      &.group-image-section {
        margin-bottom: $reg-marg;
      }
    }
  }

  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid {

    .list-menu-opt-wrapper {
      padding-left: 444px;
    }

    .mopt {
      width: 280px;
    }
  }

  .node--artist .read-more-arrow {
    left: -36px;
  }


  .node-nextprev-nav {
    margin: $reg-marg 0 0;
  }

  .wide-banner-link {
    margin: 0 auto $reg-marg auto;
  }

  .logos-fsec {
    padding-bottom: $reg-marg;
  }

  .l-footer-wrapper {
    margin-top: $reg-marg;
  }

  .page-node-1 {
    .field--name-field-page-section .field__items .field__item:first-child {
      .field--name-field-page-section-title {
        margin-bottom: $reg-marg;
      }
    }
  }

  .l-below-content {
    .block {
      margin-bottom: $reg-marg;
    }
  }

  .view-projects {
    &.view-display-id-page {

      .views-exposed-widgets {
        margin-bottom: $reg-marg;
      }

      .project-single-line {
        margin-bottom: $reg-marg;
      }

      .project-duo-line {
        margin-bottom: $reg-marg;
      }
    }
  }

  .artist-lineup-block {
    .views-row {
      margin-bottom: $reg-marg;
    }
  }

  .featured-sect {
    margin-bottom: $reg-marg;
  }

  .node {
    &--project {
      &--full {
        .group-left-right-wrapper {
          @include clearfix;
          margin-bottom: $reg-marg;
        }
      }
    }
  }

  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid label h1 {
      max-width: 512px;
      width: auto;
  }

  .node-nextprev-nav span.prev-node {
    min-width: 357px;
  }

  .node-nextprev-nav .next-node {
    min-width: 272px;
  }

  div.switcher-main {
    .attachment-before {
      .block__title {
        font-size: $base;
      }
    }
  }

  .block--system-main-menu a {
    font-size: $normal;
  }

  .switcher-main {
     > .view-content {
    //  margin-left: 0;
       max-width: 900px;
    }

    .attachment-before {
      height: calc(609px - 90px);

      .views-row {
        font-size: $bigger;
      }
    }
  }
}

@include breakpoint($laptop-smaller) {
  .l-footer-wrapper {
    background-image: linear-gradient(to bottom, $white 0, $white 50px, $light_gray 50px, $light_gray 100%);
    padding-top: 50px;
  }

  .switcher-main {
    .attachment-before {
      //min-width: 300px;
     // min-width: statcol(2);
      height: calc(609px - 50px);
    }

    > .view-content {
     // margin-left: span(2 wide, $susy-static);
    }
  }

  .node--artist-project {
    .vidplay {
      width: 144px;
      height: 144px;

      img {
        height: 20px;
        margin-top: -10px;
        margin-left: -7px;
      }

      &--teaser,
      &--full,
      &--featured {
        &:before {
          content: "";
          display: block;
          width: 87px;
          height: 87px;
        }

        &:after {
          width: 144px;
          height: 144px;
        }
      }
    }
  }
}

@include breakpoint($mini-laptop) {

  #vidmodal {
    .embedded-video {
      max-width: 70vw;
    }

    .cs-modal-header {
      max-width: 70vw;
    }

    .close-vidmodal-btn {
      right: -30px;
      height: 40px;
      width: 40px;

      &:before,
      &:after {
        height: 3px;
        width: 18px;
        margin-left: -9px;
      }
    }
  }

  .project-duo-line {
    .vidplay {
      width: 104px;
      height: 104px;

      img {
        height: 20px;
        margin-top: -10px;
        margin-left: -7px;
      }

      &--teaser,
      &--full,
      &--featured {
        &:before {
          content: "";
          display: block;
          width: 67px;
          height: 67px;
        }

        &:after {
          width: 104px;
          height: 104px;
        }
      }
    }
  }

  .featured-sect {
    .vidplay {
      width: 168px;
      height: 168px;

      img {
        height: 20px;
        margin-top: -10px;
        margin-left: -7px;
      }

      &--teaser,
      &--full,
      &--featured {
        &:before {
          content: "";
          display: block;
          width: 99px;
          height: 99px;
        }

        &:after {
          width: 168px;
          height: 168px;
        }
      }
    }
  }

  .block--system-main-menu  {
    a {
      font-size: $normal;
      padding: 24px 13px;
    }

    .gtn-button {
      a {
        padding: 24px 30px;
      }
    }
  }
}

@include breakpoint($other-tablet) {
  .below-sect-wrapper {
    .below-sect .below-banner-block .read-more {
      transform: none;
      opacity: 1;
    }
  }
}

@include breakpoint($near-tablet) {
  .l-footer-wrapper {
    background-image: linear-gradient(to bottom, $white 0, $white 50px, $light_gray 50px, $light_gray 100%);
    padding-top: 50px;
  }

  .switcher-main {
    .attachment-before {
      //min-width: 300px;
      // min-width: statcol(2);
      height: calc(501px - 50px);
    }

    > .view-content {
      max-width: 740px;
      // margin-left: span(2 wide, $susy-static);
    }
  }
}


// responsive variable stuff
$page-spacing: 80px;
$sect-marg: 50px;
$reg-marg: 80px;
$mid-marg: 50px;
$unipadding: 20px;

// updated font list
$tiny: 0.66666rem; // 12[x
$normal: 0.77777rem; // 16px -> 14px
$slight: 0.88888rem; // 18px -> 16px
$base: 1rem; // 18px base
$bigger: 1.33333rem; // 26px -> 24px
$lar: 1.36363rem; // 30px;
$large: 1.45454rem; // 32px
$larger: 1.63636rem; // 36px
$huge: 1.88888rem; // 34px
$giga: 2.44444rem; // 100px -> 44px

@include breakpoint($tablet) {

  .l-header {
  //  height: 55px;
    height: auto;
  }

  .ajax-cs-preload {
    height: auto;
    width: 160px;
  }

  // font-stuff

  .last-sect {
    &-title {
      font-size: $huge;
      line-height: 1.1;
    }
  }

/*  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid {
    .list-menu-opt-wrapper {
      padding-left: 334px;
    }

    .mopt {
      width: 260px;
    }
  }*/

  .below-sect {
  //  background: susy-svg-grid() no-repeat scroll;

    .below-banner-block {
      display: block;
      float: left;
      max-width: none;
      margin-right: span(1 wider);

      &.blb-1,
      &.blb-3 {
        margin-left: span(1) / 4;
      }

      &.blb-1,
      &.blb-2 {
        margin-bottom: 20px;
        border-bottom: 1px solid $border;
        padding-bottom: 20px;
      }

      .below-banner-title {
        margin-bottom: 10px;
      }

      .below-banner-content {
        min-height: 80px;
      }

      width: span(5 wide);

      .read-more {
        margin-top: 20px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(2n+1) {
        clear: both;
      }
    }
  }

  .slicknav_menu {
    .slicknav_menutxt {
      right: $unipadding;
    }
  }

  div.l-footer-wrapper {
    padding-top: 0;
    min-height: 0;
    background-image: none;
    background-color: transparent;
  }

  .field-artist-quote {

    .field--name-field-quote-text .field__items {
      position: static;
    //  margin-top: 40px;
      transform: none;
    }

    .field--name-field-image {
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 40px;
    }

    .field--name-field-quote-text {
      width: 100%;
      float: none;
    }
  }

  .l-footer {
    margin-left: 0;
    margin-right: 0;
  }

  div.switcher-main {
    .attachment-before {
      padding: 40px 0 40px $unipadding;
      background-color: $light_gray;
      width: span(11);

      .block__title {
        margin-bottom: 20px;
        font-size: $base;
      }

      .views-row {
        font-size: $bigger;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // end fonts

  // reg-marg start

  .view-offices .views-row-odd .group-office-image {
    float: none;
    margin-right: 0;
    width: 100%;
  }

  .view-offices .views-row-even .group-office-image {
    float: none;
  }

  .view-offices .views-row .group-office-image {
    width: 100%;
    margin-top: 20px;
  }

  .view-offices .views-row-odd .group-office-info {
    margin-top: 10px;
    width: 100%;
    float: none;
  }

  .view-offices .views-row-even .group-office-info {
    width: 100%;
    float: none;
    margin-left: 0;

  }

  .parallax-full__title {
    width: span(10);
  }

  .node-nextprev-nav {
    span.prev-node {
      &:before {
        margin-right: 16px;
      }
    }

    span.next-node {
      &:after {
        margin-left: 22px;
      }
    }
  }

  .field--name-field-page-section {
    .field-group-html-element {
      &.group-text-section {
        margin-bottom: $reg-marg;
      }

      &.group-image-section {
        margin-bottom: $reg-marg;
      }
    }
  }

  .let-us-know-wrapper {
    margin-top: $reg-marg;
  }

  .slicknav_menu .slicknav_nav {
    li a,
    li span.nolink {
      padding: 16px;
    }
  }

  .node-nextprev-nav {
    margin: $reg-marg 0 0;
  }

  .wide-banner-link {
    margin: 0 auto $reg-marg auto;
  }

  .logos-fsec {
    padding-bottom: $reg-marg;
  }

  .l-footer-wrapper {
    margin-top: $reg-marg;
  }

  .page-node-1 {
    .field--name-field-page-section .field__items .field__item:first-child {
      .field--name-field-page-section-title {
        margin-bottom: $reg-marg;
      }
    }
  }

  .l-below-content {
    .block {
      margin-bottom: $reg-marg;
    }
  }

  .view-projects {
    &.view-display-id-page {

      .views-exposed-widgets {
        margin-bottom: $reg-marg;
      }

      .project-single-line {
        margin-bottom: $reg-marg;
      }

      .project-duo-line {
        margin-bottom: $reg-marg;
      }
    }
  }

  .artist-lineup-block {
    .views-row {
      margin-bottom: $reg-marg;
    }
  }

  .featured-sect {
    margin-bottom: $reg-marg;
  }

  .node {
    &--project {
      &--full {

        .field.field--name-field-video {
          margin-bottom: $mid-marg;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .group-left-right-wrapper {
          @include clearfix;
      //    margin-bottom: $reg-marg;
          margin-bottom: $sect-marg;
        }
      }
    }
  }

  // reg-marg end

  // tabi

  .arrow-wrapper {
    &--left {
      display: none;
    }

    &--right {
      width: 100%;
      height: 100%;

      &:hover,
      &:focus {
        cursor: url('../images/slick-arrow-right.png'), auto;
      }
    }
  }

  .node--project--featured {
    .featured-project-sp {
      background-attachment: scroll;
    }
  }

  .parallax-full {
    background-attachment: scroll;
  }

  .back-link-wrap {
    margin-top: -60px;
    margin-bottom: 60px;
  }

  .node--featured {
    &.node--project .field .field__label {
      font-size: $base;
    }
  }

  .project-single-line .node--project .group-meta .field,
  .node--project--teaser .group-meta .field {
    font-size: $base;
  }

  .node--project .field .field__label {
    font-size: $normal;
  }

  .last-sect {
  //  margin-bottom: $sect-marg;
    margin-bottom: 0;
  }

  ul.pager {
  //  margin: $mid-marg 0;
    margin: $mid-marg 0 0 0;
  }

  .field-artist-quote {
    margin: $mid-marg auto 0 auto;
    padding: $mid-marg 0;
  }

  .block--views-artists-projects-block {
    margin-top: $mid-marg;

    .views-row {
      &:nth-child(2n) {
        margin-bottom: $mid-marg;
      }
    }
  }

  .node {
    &--artist {
      &--full {
        .artist-text-wrapper {
          .field--name-field-image {
            margin: $mid-marg 0;
          }
        }
      }
    }
  }

  .project-box {
    &:nth-child(5n) {
      margin-bottom: $mid-marg;
    }
  }

  .field--name-field-page-main-section-title,
  .field--name-field-page-section-title,
  .project-boxes__title {
    margin-bottom: $sect-marg;
  }

  .read-more {
    &-arrow {
      margin-top: $sect-marg;
    }
  }

  #formmodal {
    .modal-title {
      margin-bottom: $sect-marg;
    }
  }

  h1,
  .block__title {
    margin-bottom: $sect-marg;
  }

  .featured-sect {
    .fsec-title {
      margin-bottom: $sect-marg;
    }
  }


  .node {
    &--project {
      &--full {
        .field {
          margin-bottom: $sect-marg;
        }
      }
    }

    &--office {
      &--full {
        .read-more-wrapper .read-more {
          margin-top: 30px;
        //  margin-top: $sect-marg;
        }

        .group-office-contact-full {
          margin-top: 30px;
       //   margin-top: $sect-marg;
        }
      }
    }
  }

  .logos-fsec {
    .logos-title {
      margin-bottom: $sect-marg;
    }
  }

  .l-content {
    padding-top: $page-spacing;
  }

  .parallax-full {
    min-height: 760px;
  }

  .view-offices {
    .views-row {
      margin-bottom: $page-spacing;
    }
  }

  // styling

  .view-offices .views-row-even .group-office-info {
    margin-top: 10px;
  }

  .l-footer-wrapper {
   // background-image: linear-gradient(to bottom, $white 0, $white 50px, $light_gray 50px, $light_gray 100%);
    background-color: $light_gray;
    padding-top: 50px;
  }

  .switcher-main {
    .attachment-before {
      height: auto;
      float: none;
      min-width: 0;
      margin-left: 0;

      .view-footer-region-switcher {
        transform: none;
        position: static;
      }
    }

    > .view-content {
      position: static;
      max-width: none;
      margin-left: 0;
    }
  }
}

@include breakpoint($sub-tablet) {

  .slicknav_menu {
    .slicknav_nav {
      li {
        a, span.nolink {
          &.active {
            &:after {
              transform-origin: 0 0;
              content: "";
              position: absolute;
              bottom: 10px;
              left: 0;
              background-color: $yellow;
              transform: scaleX(1);
              width: 100%;
              height: 5px;
            }
          }
        }
      }
    }
  }

  .block--system-main-menu  {
    a {
      font-size: $normal;
      padding: 24px 18px;
    }

    .gtn-button {
      a {
        padding: 24px 31px;
      }
    }
  }

  .l-branding {
    max-width: 160px;

    .site-logo {
      margin: 10px 0;
      position: relative;
      top: 1px;
    }
  }

  body {
    padding-top: 55px;
  }

  .slicknav_menu .slicknav_nav {
    padding-top: 80px;
  }

  .slicknav_menu {
    display: block;
  }

  #block-system-main-menu {
    display: none;
  }
}

@include breakpoint($super-mega-mobile) {

  .main-videos-wrapper {
    .titled-mega {
      .titled-title {
        font-size: 3.55555rem;
        margin-bottom: 25px;
        line-height: 1;
        max-width: span(9);
      }

      .titled-text {
        ul li {
          font-size: $bigger;
        }
      }
    }
  }

  .node--artist--teaser .group-artist-teaser-meta-wrapper .group-artist-teaser-meta {
       position: absolute;
       width: span(10);

       .custom-title {
         //  line-height: 1;
       }
     }

  .artist-lineup-block {

    .views-row {
      width: 100%;
      margin-bottom: 30px;
    }

    .views-row-odd {
      float: none;
      margin-right: 0;
    }

    .views-row-even {
      float: none;
      margin-left: 0;
    }
  }

  #block-gtweaks-copyright {
    text-align: center;
  }

  .l-subfooter {

    .fl-nv {
      margin-right: 20px;
    }

    .fl-es .fl-tx {
      margin-right: 14px;
    }

    .cp-left {
      float: none;
      @include clearfix;
      margin-bottom: 30px;

      .cp-text {
        float: none;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        position: relative;
        top: 2px;
      }

      .social-media-icons {
        float: none;
        margin-left: 20px;
        vertical-align: middle;
        display: inline-block;
      }
    }

    .cp-right {
      float: none;
      @include clearfix;
    }
  }
}

@include breakpoint($mega-mobile) {

  .logos-fsec {
    .fsec-clients,
    .fsec-collabs {
      width: 100%;
      margin-right: 0;
      float: none;
    }

    .fsec-clients {
      margin-bottom: $reg-marg;
    }

    .logos-title {
      margin-bottom: 20px;
    }
  }

  .below-sect-wrapper.anim-loaded {
    top: -40px;
  }

  .main-videos-wrapper .titled-mega {
    margin-top: -20px;
  }

  .main-videos-wrapper {
    height: calc(90vh - 40px);
    min-height: 340px;
  }

  .node--project--featured .read-more {
    margin-top: 30px;
  }

  .node--project--featured .custom-title {
    margin-bottom: 4px;
  }

  .field--name-field-project-brand {
    .node--project .field .field__label {
      padding-bottom: 0;
    }
  }

  .node--project--featured .group-meta-wrapper {
    top: auto;
    bottom: 25px;
    left: 0;
    transform: none;
  }

  .parallax-full__title {
    line-height: 1.06;
  }

  .special-list .field--name-field-page-section-body ul li {
    padding: 30px 0;
  }

  .special-list {
    div.field--name-field-page-section-title {
      margin-bottom: 30px;
    }
  }

  .special-list .field--name-field-page-section-body ul li.highlighted-slist {
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  .special-list .field--name-field-page-section-title .field__items {
    max-width: none;
  }

  .special-list .field--name-field-page-section-title {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }

  .special-list .field--name-field-page-section-body {
    float: none;
    width: 100%;
  }

  .node-nextprev-nav span.prev-node {
    min-width: 337px;

    &:before {
      margin-right: 22px;
    }
  }

  .node-nextprev-nav span.next-node {
    min-width: 249px;

    &:after {
      margin-left: 22px;
    }
  }

  .node--project--full .group-left-side {
    float: left;
    width: span(6 wide);
    margin-right: span(1 wide);
  }

  .node--project--full .group-right-side {
    float: left;
    width: span(5);
  }

  .project-boxes {
    .project-box {
      &__icon {

        img {
          height: 40px;
          width: auto;
        }
      }
    }
  }
}

@include breakpoint($work-mobile) {
/*  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid {
    label h1 {
      float: none;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .list-menu-opt-wrapper {
      padding-left: 0;
      padding-bottom: 30px;
    }
  }*/
}

@include breakpoint($work) {

  .node--artist-project {
    .vidplay {
      width: 104px;
      height: 104px;

      img {
        height: 20px;
        margin-top: -10px;
        margin-left: -7px;
      }

      &--teaser,
      &--full,
      &--featured {
        &:before {
          content: "";
          display: block;
          width: 67px;
          height: 67px;
        }

        &:after {
          width: 104px;
          height: 104px;
        }
      }
    }
  }

  .project-single-line .node--project .group-meta .field {
    width: span(6 set-gutters 0.9);
    margin-right: gutter(set-gutters 0.9);
  }

  .node--project--teaser .group-meta .field {
    width: span(6 set-gutters 0.9);
    margin-right: gutter(set-gutters 0.9);
  }

  .node-nextprev-nav .node-nextprev {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid {
    label h1 {
      float: none;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .list-menu-opt-wrapper {
      padding-left: 0;
      padding-bottom: 30px;
    }
  }

  .node-teaser .field--type-soundcloud .field__item
  .node-nextprev .field--type-soundcloud .field__item {
    padding-bottom: 57.36%;
  }

  .view-projects.view-display-id-page {
    .project-duo-line .node {
      max-width: none;
      margin-right: 0;
      float: none;

      &:first-child {
        margin-bottom: $reg-marg;
      }
    }

    .project-duo-line .node {
      width: 100%;
      float: none;
    }

    .project-single-line .node {
      width: 100%;
      margin: 0 auto;
    }
  }

  .vidplay {
    width: 144px;
    height: 144px;

    img {
      height: 20px;
      margin-top: -10px;
      margin-left: -7px;
    }

    &--teaser,
    &--full,
    &--featured {
      &:before {
        content: "";
        display: block;
        width: 87px;
        height: 87px;
      }

      &:after {
        width: 144px;
        height: 144px;
      }
    }
  }
}

$unipadding: 10px;

@include breakpoint($bmobile) {
  #formmodal {

    .modal-form-content {
      max-width: 400px;
    }

    .form-item {
      float: none;
      margin-bottom: 20px;
      width: 100%;
      margin-right: 0;

      input,
      textarea {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

@include breakpoint($mobile) {

  .l-header,
  .l-below-content,
  .l-subfooter,
  .l-footer,
  .page-faq .l-content,
  .page-our-work .l-main,
  .node--artist--full .node__content,
  a.wide-banner-link,
  .node-type-project .l-main,
  .below-sect-wrapper,
  .featured-sect .fsec-title,
  .node--project--featured .group-meta-wrapper .group-meta,
  .logos-fsec,
  .last-sect,
  .messages,
  .block--nodeblock {
    margin-left: $unipadding;
    margin-right: $unipadding;
  }

  .main-videos-wrapper .titled-mega {
    padding-left: $unipadding;
    padding-right: $unipadding;
  }

/*  .node--artist--full .node__content {
    .artist-text-wrapper {
      .field--name-field-image {
       margin-left: -$unipadding;
        margin-right: -$unipadding;
      }
    }
  }*/

  .l-main {
    .field-collection-item-field-page-section {
      .group-text-section {
        margin-left: $unipadding;
        margin-right: $unipadding;
      }
    }

    .parallax-full__title {
      padding-left: $unipadding;
      padding-right: $unipadding;
    }
  }

  .below-sect {
    .below-banner-block {
      margin-bottom: 20px;
      border-bottom: 1px solid $border;
      padding-bottom: 20px;

      &.blb-1,
      &.blb-3 {
        margin-left: 0;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .group-office-center {
    .custom-title {
      margin-bottom: 10px;
    }

    .group-office-contact {
      margin-top: 30px;
    }
  }

  .node--project--full .group-left-side {
    float: none;
    width: 100%;
    margin-right: 0;
  }

  .node--project--full .group-right-side {
    float: none;
    width: 100%;
    margin-right: 0;
  }

  .read-more-arrow {
    margin-top: 20px;
  }

  .view-projects {
    &.view-display-id-page {

      .views-exposed-widgets {
        .views-widget-filter-field_project_category_tid {
          .mopt {
            font-size: $huge;
            line-height: 1.1;
          }

          .list-menu-opt-wrapper {
            font-size: $huge;
          }
        }
      }
    }
  }

  h1,
  .block__title {
    font-size: $huge;
    line-height: 1.1;
  }

  .featured-sect {
    .fsec-title {
      font-size: $huge;
      line-height: 1.1;
    }
  }

  .node--project--featured {
    .custom-title {
      font-size: $huge;
    }
  }

  .node--project--full {
    .field--name-field-project-release-date {
      font-size: $huge;
      line-height: 1.1;
    }
  }

  .node--artist--teaser {
    .group-artist-teaser-meta-wrapper {
      .custom-title {
        font-size: $huge;
        line-height: 1;
      }
    }
  }

  .field--name-field-page-main-section-title,
  .field--name-field-page-section-title,
  .project-boxes__title {
    font-size: $huge;
    line-height: 1.1;
  }

  .parallax-full {
    &__title {
      font-size: $huge;
    }
  }

  .node-nextprev-nav {
    .prev-node,
    .next-node {
      font-size: $huge;
    }
  }

  .node--artist--teaser .group-artist-teaser-meta-wrapper .group-artist-teaser-meta {
    /*
    position: absolute;
    width: span(10);
    */

    .custom-title {
      font-size: $bigger;
    //  line-height: 1;
    }
  }

/*  .artist-lineup-block {

    .views-row {
      width: 100%;
      margin-bottom: 30px;
    }

    .views-row-odd {
      float: none;
      margin-right: 0;
    }

    .views-row-even {
      float: none;
      margin-left: 0;
    }
  }*/

  .parallax-full {
    min-height: 600px;

    &__title {
      padding: 30px 0;
    }
  }

  .l-footer {
    margin-left: 0;
    margin-right: 0;
  }

  // mobis

  .webform-confirmation .webform-success-txt {
    br {
      display: none;
    }
  }

  #formmodal {
    .webform-component--continue-to-step {
      .throbber {
        right: 0;
      }
    }

      .file-custom-browse-wrapper {
      .throbber {
        right: 0;
      }
    }
  }

  .webform-confirmation {

    .tickbox {
      @include sprite($sprite-tickbox-mobile);
      margin: 0 auto;
    }

    min-height: 320px;
  }

  #formmodal {
    .file-original-browse-wrapper {
      &:before {
        @include sprite($sprite-upload-file-mob);
      }
    }

    .webform-component-fieldset {
      min-height: 0;
    }

    ul.file-widget {
      li {
        .file {
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }

    .file-uploaded-browser-wrapper {
      .file {
        img {
          height: 20px;
          width: auto;
        }
      }
    }
  }

  .ajax-cs-preload {
    height: auto;
    width: 120px;
  }

  #formmodal {
    .modal-title {
      margin-bottom: 20px;
    }
  }


  .main-videos-wrapper {
    height: calc(82vh - 40px);
  }

  .slicknav_menu .slicknav_nav {
    li a,
    li span.nolink {
      padding: 12px;
    }
  }

  .slicknav_menu .slicknav_nav {
    padding-top: 20px;
  }

  #vidmodal {
    .embedded-video {
      max-width: 90vw;
    }

    .cs-modal-header {
      max-width: 90vw;
      margin-bottom: 20px;
    }

    .close-vidmodal-btn {
      right: 0;
/*      height: 40px;
      width: 40px;*/

      &:before,
      &:after {
/*        height: 3px;
        width: 18px;
        margin-left: -9px;*/
      }
    }
  }

  .main-videos-wrapper {
    .titled-mega {
      .titled-title {
        font-size: $giga;
        margin-bottom: 25px;
        line-height: 1;
      }

      .titled-text ul li {
        font-size: $bigger;
        display: block;
        margin-right: 0;
        margin-bottom: 5px;
        line-height: 1.1;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .top-sect {
    margin-bottom: 40px;
  }

  .below-sect .below-banner-block {
    @include clearfix;
    width: 100%;
    margin-right: 0;
    float: none;

    .below-banner-content {
      min-height: 0;
    }

    .read-more-arrow {
      position: relative;
      left: -18px;
    }

    .regions-list {
      @include clearfix;

      a {
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  .below-sect-wrapper {
    padding: span(1) span(1) 0 span(1);
  }

  .view-frontpage-featured {
    .views-row-last {

      .read-more {
        float: left;
        margin-top: 30px;
      }

      .alt-read {
        float: right;
      }
    }
  }

  .node-nextprev-nav span.prev-node {
    min-width: 264px;
  }

  .node-nextprev-nav span.next-node {
    min-width: 200px;
  }

  .node--project--full .group-optional-right-side {
    float: none;
    width: 100%;
  }

  .node-nextprev .field--type-soundcloud .field__item {
    padding-bottom: 57.45%;

    iframe {
      max-height: none;
    }
  }

  .node--project--full .field .field__label {
    font-size: $base;
  }

  .node--office--full .group-office-info .field {
    font-size: $normal;
  }

  .node--project--full {
    .group-optional-right-side,
    .field--name-body,
    .group-below-optionals {
      font-size: $bigger;
    }

    .group-optional-right-side {
      margin-top: 20px;
      margin-bottom: $sect-marg;
    }

    .field--name-body {
      margin-top: 20px;
    }
  }

  .node--office--full .custom-title,
  .node--office--full .group-office-contact-full > div > span {
    font-size: $base;
  }

  .wide-banner {
    min-height: 200px;

    &--youtube__icon {
      @include sprite($sprite-wide-youtube-mob);
    }

    &__motto {
      font-size: $bigger;
    }

    .wide-banner__link {
      margin-top: 20px;
      font-size: $normal;
    }
  }

  div.slicknav_menu {
    .slicknav_menutxt {
      right: $unipadding;
    }
  }

  .slicknav_menu {
    margin-left: -$unipadding;
    margin-right: -$unipadding;
  }

  .block--views-artists-projects-block {
    .views-row {
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: $mid-marg;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .field-artist-quote {
    max-width: 100%;

    .field--name-field-quote-text {
      font-size: $bigger;
    }
  }

  .block--views-artists-projects-block {
    max-width: 100%;

    .custom-title {
      font-size: $base;
    }

    .field--name-body {
      font-size: $normal;
    }
  }

  .vidplay {
    width: 104px;
    height: 104px;

    img {
      height: 20px;
      margin-top: -10px;
      margin-left: -7px;
    }

    &--teaser,
    &--full,
    &--featured {
      &:before {
        content: "";
        display: block;
        width: 67px;
        height: 67px;
      }

      &:after {
        width: 104px;
        height: 104px;
      }
    }
  }

  .cat-wrap .read-more {
    margin-right: 20px;
  }

  .select-cat-menu {
   display: none;
  }

  .read-more {
    padding: 10px;
    width: 140px;
    height: 40px;
    font-weight: 400;
    line-height: 20px;


    &-arrow {
      margin-top: $sect-marg;
      line-height: 20px;

      &:after {
        line-height: 20px;
      }
    }
  }

  .field--name-field-page-section-body,
  .cat-content .field--name-field-faq-answer,
  .node--artist--full .artist-text-reg {
    font-size: $bigger;
    line-height: 1.44;
  }

  .field--name-field-artist-job-description {
    font-size: $normal;
  }

  .node--team--teaser .group-team-social .social-wrap a {
    .text {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .node--artist .read-more-arrow {
    left: -13px;
    height: 40px;
    width: 130px;
    line-height: 20px;
    font-size: $normal;

    &:after {
      line-height: 20px;
    }
  }

  .project-box {
      text-align: center;
      float: left;
      margin-right: gutter(of 10);
      width: span(5 of 10);

      &:nth-child(5n) {
        margin-right: gutter(of 10);
        margin-bottom: 0;
      }

      &:nth-child(5n+1) {
        clear: none;
      }

      &:nth-child(2n) {
        margin-right: 0;
        margin-bottom: $mid-marg;
      }

      &:nth-child(2n+1) {
        clear: both;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__icon {
        margin-bottom: 10px;
      }
    }
}

@include breakpoint($mobile-mid) {

  .view-team-lineup {

    .views-row {
      width: 100%;
      margin-right: 0;
      float: none;
      margin-bottom: $mid-marg;

      &:nth-child(2n) {
        margin-right: 0;
        margin-bottom: $mid-marg;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint($other-mobile) {
  .block--gtweaks-copyright {
    font-size: $tiny;
  }

  .l-subfooter {
    padding: 20px 0;

    .cp-text {
      letter-spacing: -0.02em;
    }

    .cp-left {
      margin-bottom: 20px;
    }

    .social-media-icons {
      margin-left: 0;

      a {
        &:last-child {
          li {
            margin-right: 0;
          }
        }
      }

      &__item {
        margin-right: 6px;
        &--linkedin {
          @include sprite($sprite-linkedin-footer-mob);
        }

        &--youtube {
          // @include sprite($sprite-youtube-footer-mob);
        }
      }
    }

    .fl-nv {
      @include sprite($sprite-neverending-logo-mob);
      margin-right: 10px;
      margin-bottom: 2px;
    }

    .fl-es {

      .fl-tx {
        margin-right: 6px;
      }

      .fl-ic {
     //   position: relative;
      //  top: -3px;
       @include sprite($sprite-esepia-logo-mob);
      }
    }
  }
}

@include breakpoint($small-mobile) {
  .l-subfooter {
    .cp-left {
      .social-media-icons {
        float: none;
        margin-left: 0;
        margin-top: 0;
        vertical-align: middle;
        display: inline-block;

        ul {
          a:last-child {
           li {
             margin-right: 0;
           }
          }
        }
      }
    }
  }
}

@include breakpoint($mini-mobile) {
  #formmodal .form-item label {
    letter-spacing: -0.01em;
  }
}