// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-big-arrow-name: 'sprite_big-arrow';
$sprite-big-arrow-x: 110px;
$sprite-big-arrow-y: 245px;
$sprite-big-arrow-offset-x: -110px;
$sprite-big-arrow-offset-y: -245px;
$sprite-big-arrow-width: 31px;
$sprite-big-arrow-height: 21px;
$sprite-big-arrow-total-width: 386px;
$sprite-big-arrow-total-height: 310px;
$sprite-big-arrow-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-big-arrow: (110px, 245px, -110px, -245px, 31px, 21px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_big-arrow', );
$sprite-checked-yellow-name: 'sprite_checked-yellow';
$sprite-checked-yellow-x: 32px;
$sprite-checked-yellow-y: 280px;
$sprite-checked-yellow-offset-x: -32px;
$sprite-checked-yellow-offset-y: -280px;
$sprite-checked-yellow-width: 28px;
$sprite-checked-yellow-height: 28px;
$sprite-checked-yellow-total-width: 386px;
$sprite-checked-yellow-total-height: 310px;
$sprite-checked-yellow-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-checked-yellow: (32px, 280px, -32px, -280px, 28px, 28px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_checked-yellow', );
$sprite-esepia-logo-mob-name: 'sprite_esepia-logo-mob';
$sprite-esepia-logo-mob-x: 46px;
$sprite-esepia-logo-mob-y: 245px;
$sprite-esepia-logo-mob-offset-x: -46px;
$sprite-esepia-logo-mob-offset-y: -245px;
$sprite-esepia-logo-mob-width: 62px;
$sprite-esepia-logo-mob-height: 22px;
$sprite-esepia-logo-mob-total-width: 386px;
$sprite-esepia-logo-mob-total-height: 310px;
$sprite-esepia-logo-mob-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-esepia-logo-mob: (46px, 245px, -46px, -245px, 62px, 22px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_esepia-logo-mob', );
$sprite-esepia-logo-name: 'sprite_esepia-logo';
$sprite-esepia-logo-x: 294px;
$sprite-esepia-logo-y: 162px;
$sprite-esepia-logo-offset-x: -294px;
$sprite-esepia-logo-offset-y: -162px;
$sprite-esepia-logo-width: 85px;
$sprite-esepia-logo-height: 30px;
$sprite-esepia-logo-total-width: 386px;
$sprite-esepia-logo-total-height: 310px;
$sprite-esepia-logo-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-esepia-logo: (294px, 162px, -294px, -162px, 85px, 30px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_esepia-logo', );
$sprite-facebook-name: 'sprite_facebook';
$sprite-facebook-x: 199px;
$sprite-facebook-y: 245px;
$sprite-facebook-offset-x: -199px;
$sprite-facebook-offset-y: -245px;
$sprite-facebook-width: 26px;
$sprite-facebook-height: 20px;
$sprite-facebook-total-width: 386px;
$sprite-facebook-total-height: 310px;
$sprite-facebook-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-facebook: (199px, 245px, -199px, -245px, 26px, 20px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_facebook', );
$sprite-linkedin-c-name: 'sprite_linkedin-c';
$sprite-linkedin-c-x: 0px;
$sprite-linkedin-c-y: 280px;
$sprite-linkedin-c-offset-x: 0px;
$sprite-linkedin-c-offset-y: -280px;
$sprite-linkedin-c-width: 30px;
$sprite-linkedin-c-height: 30px;
$sprite-linkedin-c-total-width: 386px;
$sprite-linkedin-c-total-height: 310px;
$sprite-linkedin-c-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-linkedin-c: (0px, 280px, 0px, -280px, 30px, 30px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_linkedin-c', );
$sprite-linkedin-footer-mob-name: 'sprite_linkedin-footer-mob';
$sprite-linkedin-footer-mob-x: 295px;
$sprite-linkedin-footer-mob-y: 245px;
$sprite-linkedin-footer-mob-offset-x: -295px;
$sprite-linkedin-footer-mob-offset-y: -245px;
$sprite-linkedin-footer-mob-width: 17px;
$sprite-linkedin-footer-mob-height: 16px;
$sprite-linkedin-footer-mob-total-width: 386px;
$sprite-linkedin-footer-mob-total-height: 310px;
$sprite-linkedin-footer-mob-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-linkedin-footer-mob: (295px, 245px, -295px, -245px, 17px, 16px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_linkedin-footer-mob', );
$sprite-linkedin-footer-name: 'sprite_linkedin-footer';
$sprite-linkedin-footer-x: 252px;
$sprite-linkedin-footer-y: 245px;
$sprite-linkedin-footer-offset-x: -252px;
$sprite-linkedin-footer-offset-y: -245px;
$sprite-linkedin-footer-width: 21px;
$sprite-linkedin-footer-height: 20px;
$sprite-linkedin-footer-total-width: 386px;
$sprite-linkedin-footer-total-height: 310px;
$sprite-linkedin-footer-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-linkedin-footer: (252px, 245px, -252px, -245px, 21px, 20px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_linkedin-footer', );
$sprite-menu-close-name: 'sprite_menu-close';
$sprite-menu-close-x: 314px;
$sprite-menu-close-y: 245px;
$sprite-menu-close-offset-x: -314px;
$sprite-menu-close-offset-y: -245px;
$sprite-menu-close-width: 16px;
$sprite-menu-close-height: 16px;
$sprite-menu-close-total-width: 386px;
$sprite-menu-close-total-height: 310px;
$sprite-menu-close-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-menu-close: (314px, 245px, -314px, -245px, 16px, 16px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_menu-close', );
$sprite-menu-open-name: 'sprite_menu-open';
$sprite-menu-open-x: 275px;
$sprite-menu-open-y: 245px;
$sprite-menu-open-offset-x: -275px;
$sprite-menu-open-offset-y: -245px;
$sprite-menu-open-width: 18px;
$sprite-menu-open-height: 17px;
$sprite-menu-open-total-width: 386px;
$sprite-menu-open-total-height: 310px;
$sprite-menu-open-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-menu-open: (275px, 245px, -275px, -245px, 18px, 17px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_menu-open', );
$sprite-neverending-logo-mob-name: 'sprite_neverending-logo-mob';
$sprite-neverending-logo-mob-x: 161px;
$sprite-neverending-logo-mob-y: 226px;
$sprite-neverending-logo-mob-offset-x: -161px;
$sprite-neverending-logo-mob-offset-y: -226px;
$sprite-neverending-logo-mob-width: 112px;
$sprite-neverending-logo-mob-height: 12px;
$sprite-neverending-logo-mob-total-width: 386px;
$sprite-neverending-logo-mob-total-height: 310px;
$sprite-neverending-logo-mob-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-neverending-logo-mob: (161px, 226px, -161px, -226px, 112px, 12px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_neverending-logo-mob', );
$sprite-neverending-logo-name: 'sprite_neverending-logo';
$sprite-neverending-logo-x: 0px;
$sprite-neverending-logo-y: 226px;
$sprite-neverending-logo-offset-x: 0px;
$sprite-neverending-logo-offset-y: -226px;
$sprite-neverending-logo-width: 159px;
$sprite-neverending-logo-height: 17px;
$sprite-neverending-logo-total-width: 386px;
$sprite-neverending-logo-total-height: 310px;
$sprite-neverending-logo-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-neverending-logo: (0px, 226px, 0px, -226px, 159px, 17px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_neverending-logo', );
$sprite-readmore-arrow-black-name: 'sprite_readmore-arrow-black';
$sprite-readmore-arrow-black-x: 341px;
$sprite-readmore-arrow-black-y: 194px;
$sprite-readmore-arrow-black-offset-x: -341px;
$sprite-readmore-arrow-black-offset-y: -194px;
$sprite-readmore-arrow-black-width: 22px;
$sprite-readmore-arrow-black-height: 14px;
$sprite-readmore-arrow-black-total-width: 386px;
$sprite-readmore-arrow-black-total-height: 310px;
$sprite-readmore-arrow-black-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-readmore-arrow-black: (341px, 194px, -341px, -194px, 22px, 14px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_readmore-arrow-black', );
$sprite-readmore-arrow-name: 'sprite_readmore-arrow';
$sprite-readmore-arrow-x: 317px;
$sprite-readmore-arrow-y: 194px;
$sprite-readmore-arrow-offset-x: -317px;
$sprite-readmore-arrow-offset-y: -194px;
$sprite-readmore-arrow-width: 22px;
$sprite-readmore-arrow-height: 14px;
$sprite-readmore-arrow-total-width: 386px;
$sprite-readmore-arrow-total-height: 310px;
$sprite-readmore-arrow-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-readmore-arrow: (317px, 194px, -317px, -194px, 22px, 14px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_readmore-arrow', );
$sprite-tickbox-mobile-name: 'sprite_tickbox-mobile';
$sprite-tickbox-mobile-x: 226px;
$sprite-tickbox-mobile-y: 0px;
$sprite-tickbox-mobile-offset-x: -226px;
$sprite-tickbox-mobile-offset-y: 0px;
$sprite-tickbox-mobile-width: 160px;
$sprite-tickbox-mobile-height: 160px;
$sprite-tickbox-mobile-total-width: 386px;
$sprite-tickbox-mobile-total-height: 310px;
$sprite-tickbox-mobile-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-tickbox-mobile: (226px, 0px, -226px, 0px, 160px, 160px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_tickbox-mobile', );
$sprite-tickbox-name: 'sprite_tickbox';
$sprite-tickbox-x: 0px;
$sprite-tickbox-y: 0px;
$sprite-tickbox-offset-x: 0px;
$sprite-tickbox-offset-y: 0px;
$sprite-tickbox-width: 224px;
$sprite-tickbox-height: 224px;
$sprite-tickbox-total-width: 386px;
$sprite-tickbox-total-height: 310px;
$sprite-tickbox-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-tickbox: (0px, 0px, 0px, 0px, 224px, 224px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_tickbox', );
$sprite-unchecked-yellow-name: 'sprite_unchecked-yellow';
$sprite-unchecked-yellow-x: 62px;
$sprite-unchecked-yellow-y: 280px;
$sprite-unchecked-yellow-offset-x: -62px;
$sprite-unchecked-yellow-offset-y: -280px;
$sprite-unchecked-yellow-width: 28px;
$sprite-unchecked-yellow-height: 28px;
$sprite-unchecked-yellow-total-width: 386px;
$sprite-unchecked-yellow-total-height: 310px;
$sprite-unchecked-yellow-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-unchecked-yellow: (62px, 280px, -62px, -280px, 28px, 28px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_unchecked-yellow', );
$sprite-upload-file-mob-name: 'sprite_upload-file-mob';
$sprite-upload-file-mob-x: 227px;
$sprite-upload-file-mob-y: 245px;
$sprite-upload-file-mob-offset-x: -227px;
$sprite-upload-file-mob-offset-y: -245px;
$sprite-upload-file-mob-width: 23px;
$sprite-upload-file-mob-height: 20px;
$sprite-upload-file-mob-total-width: 386px;
$sprite-upload-file-mob-total-height: 310px;
$sprite-upload-file-mob-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-upload-file-mob: (227px, 245px, -227px, -245px, 23px, 20px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_upload-file-mob', );
$sprite-upload-file-name: 'sprite_upload-file';
$sprite-upload-file-x: 92px;
$sprite-upload-file-y: 280px;
$sprite-upload-file-offset-x: -92px;
$sprite-upload-file-offset-y: -280px;
$sprite-upload-file-width: 27px;
$sprite-upload-file-height: 24px;
$sprite-upload-file-total-width: 386px;
$sprite-upload-file-total-height: 310px;
$sprite-upload-file-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-upload-file: (92px, 280px, -92px, -280px, 27px, 24px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_upload-file', );
$sprite-vimeo-name: 'sprite_vimeo';
$sprite-vimeo-x: 171px;
$sprite-vimeo-y: 245px;
$sprite-vimeo-offset-x: -171px;
$sprite-vimeo-offset-y: -245px;
$sprite-vimeo-width: 26px;
$sprite-vimeo-height: 20px;
$sprite-vimeo-total-width: 386px;
$sprite-vimeo-total-height: 310px;
$sprite-vimeo-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-vimeo: (171px, 245px, -171px, -245px, 26px, 20px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_vimeo', );
$sprite-wide-youtube-mob-name: 'sprite_wide-youtube-mob';
$sprite-wide-youtube-mob-x: 0px;
$sprite-wide-youtube-mob-y: 245px;
$sprite-wide-youtube-mob-offset-x: 0px;
$sprite-wide-youtube-mob-offset-y: -245px;
$sprite-wide-youtube-mob-width: 44px;
$sprite-wide-youtube-mob-height: 33px;
$sprite-wide-youtube-mob-total-width: 386px;
$sprite-wide-youtube-mob-total-height: 310px;
$sprite-wide-youtube-mob-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-wide-youtube-mob: (0px, 245px, 0px, -245px, 44px, 33px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_wide-youtube-mob', );
$sprite-youtube-banner-name: 'sprite_youtube-banner';
$sprite-youtube-banner-x: 226px;
$sprite-youtube-banner-y: 162px;
$sprite-youtube-banner-offset-x: -226px;
$sprite-youtube-banner-offset-y: -162px;
$sprite-youtube-banner-width: 66px;
$sprite-youtube-banner-height: 50px;
$sprite-youtube-banner-total-width: 386px;
$sprite-youtube-banner-total-height: 310px;
$sprite-youtube-banner-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-youtube-banner: (226px, 162px, -226px, -162px, 66px, 50px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_youtube-banner', );
$sprite-youtube-footer-mob-name: 'sprite_youtube-footer-mob';
$sprite-youtube-footer-mob-x: 294px;
$sprite-youtube-footer-mob-y: 194px;
$sprite-youtube-footer-mob-offset-x: -294px;
$sprite-youtube-footer-mob-offset-y: -194px;
$sprite-youtube-footer-mob-width: 21px;
$sprite-youtube-footer-mob-height: 16px;
$sprite-youtube-footer-mob-total-width: 386px;
$sprite-youtube-footer-mob-total-height: 310px;
$sprite-youtube-footer-mob-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-youtube-footer-mob: (294px, 194px, -294px, -194px, 21px, 16px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_youtube-footer-mob', );
$sprite-youtube-footer-name: 'sprite_youtube-footer';
$sprite-youtube-footer-x: 143px;
$sprite-youtube-footer-y: 245px;
$sprite-youtube-footer-offset-x: -143px;
$sprite-youtube-footer-offset-y: -245px;
$sprite-youtube-footer-width: 26px;
$sprite-youtube-footer-height: 20px;
$sprite-youtube-footer-total-width: 386px;
$sprite-youtube-footer-total-height: 310px;
$sprite-youtube-footer-image: '../images/sprites/build/sprites.png?v=1602686616975';
$sprite-youtube-footer: (143px, 245px, -143px, -245px, 26px, 20px, 386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', 'sprite_youtube-footer', );
$spritesheet-width: 386px;
$spritesheet-height: 310px;
$spritesheet-image: '../images/sprites/build/sprites.png?v=1602686616975';
$spritesheet-sprites: ($sprite-big-arrow, $sprite-checked-yellow, $sprite-esepia-logo-mob, $sprite-esepia-logo, $sprite-facebook, $sprite-linkedin-c, $sprite-linkedin-footer-mob, $sprite-linkedin-footer, $sprite-menu-close, $sprite-menu-open, $sprite-neverending-logo-mob, $sprite-neverending-logo, $sprite-readmore-arrow-black, $sprite-readmore-arrow, $sprite-tickbox-mobile, $sprite-tickbox, $sprite-unchecked-yellow, $sprite-upload-file-mob, $sprite-upload-file, $sprite-vimeo, $sprite-wide-youtube-mob, $sprite-youtube-banner, $sprite-youtube-footer-mob, $sprite-youtube-footer, );
$spritesheet: (386px, 310px, '../images/sprites/build/sprites.png?v=1602686616975', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
